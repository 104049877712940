/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, jsx } from "theme-ui"
import { graphql, PageProps } from "gatsby"

import type { TermsAndConditionsQuery } from "../../graphql-types"

import Layout from "components/Layout"
import MdContent from "components/common/MdContent"

interface TermsAndConditionsProps extends PageProps {
  data: TermsAndConditionsQuery
}

const TermsAndConditions = ({ data, ...props }: TermsAndConditionsProps) => {
  return (
    <Layout seo={data.strapiTermsAndConditions?.seo} location={props.location}>
      <Box as="article" sx={{ my: [12, null, 24] }}>
        <Container variant="layout.narrow">
          <MdContent source={data.strapiTermsAndConditions?.content} />
        </Container>
      </Box>
    </Layout>
  )
}

export default TermsAndConditions

export const query = graphql`
  query TermsAndConditions {
    strapiTermsAndConditions {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      content
    }
  }
`
